import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, TransitionGroup as _TransitionGroup, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30485904"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "no-redirect"
};
const _hoisted_2 = ["onClick"];
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const levelList = ref([]);
    function getBreadcrumb() {
      // only show routes with meta.title
      let matched = route.matched.filter(item => item.meta && item.meta.title);
      const first = matched[0];
      // 判断是否为首页
      if (!isDashboard(first)) {
        matched = [{
          path: '/index',
          meta: {
            title: '首页'
          }
        }].concat(matched);
      }
      levelList.value = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
    }
    function isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim() === 'Index';
    }
    function handleLink(item) {
      const {
        redirect,
        path
      } = item;
      if (redirect) {
        router.push(redirect);
        return;
      }
      router.push(path);
    }
    watchEffect(() => {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return;
      }
      getBreadcrumb();
    });
    getBreadcrumb();
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
      return _openBlock(), _createBlock(_component_el_breadcrumb, {
        class: "app-breadcrumb",
        separator: "/"
      }, {
        default: _withCtx(() => [_createVNode(_TransitionGroup, {
          name: "breadcrumb"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(levelList.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: item.path
            }, {
              default: _withCtx(() => [item.redirect === 'noRedirect' || index == levelList.value.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(item.meta.title), 1)) : (_openBlock(), _createElementBlock("a", {
                key: 1,
                onClick: _withModifiers($event => handleLink(item), ["prevent"])
              }, _toDisplayString(item.meta.title), 9, _hoisted_2))]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        })]),
        _: 1
      });
    };
  }
};