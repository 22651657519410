import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
import { getDictionary } from '@/utils/geshanzsq';
export default {
  __name: 'index',
  props: {
    // 字典编码
    code: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const dictionaryDataList = ref([]);
    function getDictionaryDataList() {
      dictionaryDataList.value = getDictionary(props.code);
    }
    getDictionaryDataList();
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dictionaryDataList.value, dictionary => {
        return _openBlock(), _createBlock(_component_el_option, {
          key: dictionary.id,
          label: dictionary.dictionaryLabel,
          value: dictionary.dictionaryValue
        }, null, 8, ["label", "value"]);
      }), 128))]);
    };
  }
};