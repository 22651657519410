import { unref as _unref, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { isExternal } from '@/utils/validate';
import { computed } from '@vue/runtime-core';
export default {
  __name: 'Link',
  props: {
    to: {
      type: [String, Object],
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const isExt = computed(() => {
      return isExternal(props.to);
    });
    const type = computed(() => {
      if (isExt.value) {
        return 'a';
      }
      return 'router-link';
    });
    function linkProps() {
      if (isExt.value) {
        return {
          href: props.to,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        to: props.to
      };
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_resolveDynamicComponent(_unref(type)), _normalizeProps(_guardReactiveProps(linkProps())), {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 16);
    };
  }
};