import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
import { ref, watch } from 'vue';
import { getDictionaryData } from '@/utils/geshanzsq';
export default {
  __name: 'index',
  props: {
    // 字典编码
    code: {
      type: String,
      required: true
    },
    // 字典值
    value: {
      type: [Number, String, Array]
    },
    // 主题
    effect: {
      type: String,
      default: 'light'
    },
    // 是否圆角
    round: {
      type: Boolean,
      default: false
    },
    // 大小
    size: {
      type: String,
      default: 'default'
    }
  },
  setup(__props) {
    const props = __props;

    // 显示内容
    const label = ref('');
    // 类型
    const type = ref('');

    /**
     * 监听变化，否则数据有变动不会生效
     */
    watch(props, (nweProps, oldProps) => {
      getLabel();
    });

    /**
     * 获取标签
     */
    function getLabel() {
      const dictionaryData = getDictionaryData(props.code, props.value);
      if (dictionaryData !== undefined) {
        label.value = dictionaryData.dictionaryLabel;
        type.value = dictionaryData.classType;
      }
    }
    getLabel();
    return (_ctx, _cache) => {
      const _component_el_tag = _resolveComponent("el-tag");
      return type.value === '' || type.value === 'default' ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(label.value), 1)) : (_openBlock(), _createBlock(_component_el_tag, {
        key: 1,
        effect: __props.effect,
        type: type.value,
        round: __props.round,
        size: __props.size
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(label.value), 1)]),
        _: 1
      }, 8, ["effect", "type", "round", "size"]));
    };
  }
};