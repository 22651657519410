import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2bb0c75a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["xlink:href"];
import { computed } from 'vue';
import { isExternal } from '@/utils/validate';
export default {
  __name: 'index',
  props: {
    // icon 图标
    iconName: {
      type: String,
      required: true
    },
    // 图标类名
    className: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;

    /**
     * 判断是否为外部资源
     */
    const hasExternal = computed(() => isExternal(props.iconName));

    /**
     * 外部图标样式
     */
    const styleExternalIcon = computed(() => ({
      mask: `url(${props.iconName}) no-repeat 50% 50%`,
      '-webkit-mask': `url(${props.iconName}) no-repeat 50% 50%`
    }));

    /**
     * 内部图标
     */
    const internalIconName = computed(() => `#icon-${props.iconName}`);

    /**
     * 样式设置
     */
    const svgClass = computed(() => props.className ? 'svg-icon ' + props.className : 'svg-icon');
    return (_ctx, _cache) => {
      return hasExternal.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle(styleExternalIcon.value),
        class: "svg-external-icon svg-icon"
      }, null, 4)) : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        class: _normalizeClass(svgClass.value),
        "aria-hidden": "true"
      }, [_createElementVNode("use", {
        "xlink:href": internalIconName.value
      }, null, 8, _hoisted_1)], 2));
    };
  }
};