import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
import { computed, ref, watchEffect } from '@vue/runtime-core';
import { useWindowSize } from '@vueuse/core';
import { useStore } from 'vuex';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Settings from './components/Settings';
import AppMain from './components/AppMain.vue';
import TagsView from './components/TagsView';
const defaultWidth = 992;
export default {
  __name: 'index',
  setup(__props) {
    const store = useStore();
    const theme = computed(() => store.getters['settings/theme']);
    const sidebarConfig = computed(() => store.getters['app/sidebar']);
    const device = computed(() => store.getters['app/device']);
    const needTagsView = computed(() => store.getters['settings/tagsView']);
    const fixedHeader = computed(() => store.getters['settings/fixedHeader']);
    const classObj = computed(() => ({
      hideSidebar: !sidebarConfig.value.opened,
      openSidebar: sidebarConfig.value.opened,
      withoutAnimation: sidebarConfig.value.withoutAnimation,
      mobile: device.value === 'mobile'
    }));
    const {
      width
    } = useWindowSize();
    // 参考 Bootstrap 的响应式设计
    watchEffect(() => {
      if (device.value === 'mobile' && sidebarConfig.value.opened) {
        store.dispatch('app/closeSideBar', {
          withoutAnimation: false
        });
      }
      if (width.value - 1 < defaultWidth) {
        store.dispatch('app/toggleDevice', 'mobile');
        store.dispatch('app/closeSideBar', {
          withoutAnimation: true
        });
      } else {
        store.dispatch('app/toggleDevice', 'desktop');
      }
    });
    function handleClickOutside() {
      store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
    const settingRef = ref(null);
    function setLayout() {
      settingRef.value.openSetting();
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_unref(classObj), "app-wrapper"]),
        style: _normalizeStyle({
          '--current-color': _unref(theme)
        })
      }, [_unref(device) === 'mobile' && _unref(sidebarConfig).opened ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "drawer-bg",
        onClick: handleClickOutside
      })) : _createCommentVNode("", true), !_unref(sidebarConfig).hide ? (_openBlock(), _createBlock(_unref(Sidebar), {
        key: 1,
        class: "sidebar-container"
      })) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass([{
          hasTagsView: _unref(needTagsView),
          sidebarHide: _unref(sidebarConfig).hide
        }, "main-container"])
      }, [_createElementVNode("div", {
        class: _normalizeClass({
          'fixed-header': _unref(fixedHeader)
        })
      }, [_createVNode(_unref(Navbar), {
        onSetLayout: setLayout
      }), _unref(needTagsView) ? (_openBlock(), _createBlock(_unref(TagsView), {
        key: 0
      })) : _createCommentVNode("", true)], 2), _createVNode(AppMain), _createVNode(_unref(Settings), {
        ref_key: "settingRef",
        ref: settingRef
      }, null, 512)], 2)], 6);
    };
  }
};