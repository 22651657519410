import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { constantRoutes } from '@/router';
import { isHttp } from '@/utils/validate';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

// 顶部栏初始数

export default {
  __name: 'index',
  setup(__props) {
    const visibleNumber = ref(null);
    // 当前激活菜单的 index
    const currentIndex = ref(null);
    // 隐藏侧边栏路由
    const hideList = ['/index', '/user/profile'];
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // 主题颜色
    const theme = computed(() => store.getters['settings/theme']);
    // 所有的路由信息
    const routers = computed(() => store.getters['permission/topbarRouters']);

    // 顶部显示菜单
    const topMenus = computed(() => {
      const topMenus = [];
      routers.value.forEach(menu => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === '/') {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    });

    // 设置子路由
    const childrenMenus = computed(() => {
      const childrenMenus = [];
      routers.value.forEach(router => {
        for (const item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === '/') {
              router.children[item].path = '/' + router.children[item].path;
            } else {
              if (!isHttp(router.children[item].path)) {
                router.children[item].path = router.path + '/' + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    });

    // 默认激活的菜单
    const activeMenu = computed(() => {
      const path = route.path;
      let activePath = path;
      if (path !== undefined && path.lastIndexOf('/') > 0 && hideList.indexOf(path) === -1) {
        const tmpPath = path.substring(1, path.length);
        activePath = '/' + tmpPath.substring(0, tmpPath.indexOf('/'));
        store.dispatch('app/toggleSideBarHide', false);
      } else if (!route.children) {
        activePath = path;
        store.dispatch('app/toggleSideBarHide', true);
      }
      activeRoutes(activePath);
      return activePath;
    });
    function setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 3;
      visibleNumber.value = parseInt(width / 85);
    }
    function handleSelect(key, keyPath) {
      currentIndex.value = key;
      const route = routers.value.find(item => item.path === key);
      if (isHttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, '_blank');
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        router.push({
          path: key
        });
        store.dispatch('app/toggleSideBarHide', true);
      } else {
        // 显示左侧联动菜单
        activeRoutes(key);
        store.dispatch('app/toggleSideBarHide', false);
      }
    }
    function activeRoutes(key) {
      const routes = [];
      if (childrenMenus.value && childrenMenus.value.length > 0) {
        childrenMenus.value.forEach(item => {
          if (key === item.parentPath || key === 'index' && item.path === '') {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        store.commit('permission/setSidebarRouters', routes);
      }
      return routes;
    }
    onMounted(() => {
      window.addEventListener('resize', setVisibleNumber);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setVisibleNumber);
    });
    onMounted(() => {
      setVisibleNumber();
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": activeMenu.value,
        mode: "horizontal",
        onSelect: handleSelect
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topMenus.value, (item, index) => {
          return _openBlock(), _createElementBlock(_Fragment, null, [index < visibleNumber.value ? (_openBlock(), _createBlock(_component_el_menu_item, {
            style: _normalizeStyle({
              '--theme': theme.value
            }),
            index: item.path,
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_svg_icon, {
              "icon-name": item.meta.icon
            }, null, 8, ["icon-name"]), _createTextVNode(" " + _toDisplayString(item.meta.title), 1)]),
            _: 2
          }, 1032, ["style", "index"])) : _createCommentVNode("", true)], 64);
        }), 256)), topMenus.value.length > visibleNumber.value ? (_openBlock(), _createBlock(_component_el_sub_menu, {
          key: 0,
          style: _normalizeStyle({
            '--theme': theme.value
          }),
          index: "more"
        }, {
          title: _withCtx(() => [_createTextVNode("更多菜单")]),
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topMenus.value, (item, index) => {
            return _openBlock(), _createElementBlock(_Fragment, null, [index >= visibleNumber.value ? (_openBlock(), _createBlock(_component_el_menu_item, {
              index: item.path,
              key: index
            }, {
              default: _withCtx(() => [_createVNode(_component_svg_icon, {
                "icon-name": item.meta.icon
              }, null, 8, ["icon-name"]), _createTextVNode(" " + _toDisplayString(item.meta.title), 1)]),
              _: 2
            }, 1032, ["index"])) : _createCommentVNode("", true)], 64);
          }), 256))]),
          _: 1
        }, 8, ["style"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["default-active"]);
    };
  }
};