import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4cc8268c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "size-icon--style"
};
import { computed, getCurrentInstance, ref } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'index',
  setup(__props) {
    const store = useStore();
    const size = computed(() => store.getters['app/size']);
    const {
      proxy
    } = getCurrentInstance();
    const sizeOptions = ref([{
      label: '较大',
      value: 'large'
    }, {
      label: '默认',
      value: 'default'
    }, {
      label: '稍小',
      value: 'small'
    }]);
    function handleSetSize(size) {
      proxy.$modal.loading('正在设置布局大小，请稍候...');
      store.dispatch('app/setSize', size);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_dropdown, {
        trigger: "click",
        onCommand: handleSetSize
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sizeOptions.value, item => {
            return _openBlock(), _createBlock(_component_el_dropdown_item, {
              key: item.value,
              disabled: size.value === item.value,
              command: item.value
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["disabled", "command"]);
          }), 128))]),
          _: 1
        })]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
          "class-name": "size-icon",
          "icon-name": "size"
        })])]),
        _: 1
      })]);
    };
  }
};