import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
import Fuse from 'fuse.js';
import { getNormalPath } from '@/utils/geshanzsq';
import { isHttp } from '@/utils/validate';
import { computed, nextTick, onMounted, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
export default {
  __name: 'index',
  setup(__props) {
    const search = ref('');
    const options = ref([]);
    const searchPool = ref([]);
    const show = ref(false);
    const fuse = ref(undefined);
    const headerSearchSelectRef = ref(null);
    const store = useStore();
    const router = useRouter();
    const routes = computed(() => store.getters['permission/routes']);
    function click() {
      show.value = !show.value;
      if (show.value) {
        headerSearchSelectRef.value && headerSearchSelectRef.value.focus();
      }
    }
    function close() {
      headerSearchSelectRef.value && headerSearchSelectRef.value.blur();
      options.value = [];
      show.value = false;
    }
    function change(val) {
      const path = val.path;
      if (isHttp(path)) {
        // http(s):// 路径新窗口打开
        const pindex = path.indexOf('http');
        window.open(path.substr(pindex, path.length), '_blank');
      } else {
        router.push(path);
      }
      search.value = '';
      options.value = [];
      nextTick(() => {
        show.value = false;
      });
    }
    function initFuse(list) {
      fuse.value = new Fuse(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7
        }, {
          name: 'path',
          weight: 0.3
        }]
      });
    }
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    function generateRoutes(routes, basePath = '', prefixTitle = []) {
      let res = [];
      if (routes === undefined) {
        return res;
      }
      for (const r of routes) {
        // skip hidden router
        if (r.hidden) {
          continue;
        }
        const p = r.path.length > 0 && r.path[0] === '/' ? r.path : '/' + r.path;
        const data = {
          path: !isHttp(r.path) ? getNormalPath(basePath + p) : r.path,
          title: [...prefixTitle]
        };
        if (r.meta && r.meta.title) {
          data.title = [...data.title, r.meta.title];
          if (r.redirect !== 'noRedirect') {
            // only push the routes with title
            // special case: need to exclude parent router without redirect
            res.push(data);
          }
        }

        // recursive child routes
        if (r.children) {
          const tempRoutes = generateRoutes(r.children, data.path, data.title);
          if (tempRoutes.length >= 1) {
            res = [...res, ...tempRoutes];
          }
        }
      }
      return res;
    }
    function querySearch(query) {
      if (query !== '') {
        options.value = fuse.value.search(query);
      } else {
        options.value = [];
      }
    }
    onMounted(() => {
      searchPool.value = generateRoutes(routes.value);
    });
    watchEffect(() => {
      searchPool.value = generateRoutes(routes.value);
    });
    watch(show, value => {
      if (value) {
        document.body.addEventListener('click', close);
      } else {
        document.body.removeEventListener('click', close);
      }
    });
    watch(searchPool, list => {
      initFuse(list);
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{
          show: show.value
        }, "header-search"])
      }, [_createVNode(_component_svg_icon, {
        "class-name": "search-icon",
        "icon-name": "search",
        onClick: _withModifiers(click, ["stop"])
      }), _createVNode(_component_el_select, {
        ref_key: "headerSearchSelectRef",
        ref: headerSearchSelectRef,
        modelValue: search.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => search.value = $event),
        "remote-method": querySearch,
        filterable: "",
        "default-first-option": "",
        remote: "",
        placeholder: "搜索",
        class: "header-search-select",
        onChange: change
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, option => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: option.item.path,
            value: option.item,
            label: option.item.title.join(' > ')
          }, null, 8, ["value", "label"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])], 2);
    };
  }
};