import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2eca74ce"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-main"
};
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default {
  __name: 'AppMain',
  setup(__props) {
    const store = useStore();
    const route = useRoute();
    store.dispatch('tagsView/addCachedView', route);
    const cachedViews = computed(() => {
      return store.getters['tagsView/cachedViews'];
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("section", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component,
          route
        }) => [_createVNode(_Transition, {
          name: "fade-transform",
          mode: "out-in"
        }, {
          default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
            include: _unref(cachedViews)
          }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: route.path
          }))], 1032, ["include"]))]),
          _: 2
        }, 1024)]),
        _: 1
      })]);
    };
  }
};