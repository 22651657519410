import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useFullscreen } from '@vueuse/core';
export default {
  __name: 'index',
  setup(__props) {
    const {
      isFullscreen,
      toggle
    } = useFullscreen();
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_svg_icon, {
        "icon-name": _unref(isFullscreen) ? 'exit-fullscreen' : 'fullscreen',
        onClick: _unref(toggle)
      }, null, 8, ["icon-name", "onClick"])]);
    };
  }
};