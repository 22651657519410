import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, createSlots as _createSlots } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = ["title"];
const _hoisted_3 = ["title"];
import { isExternal } from '@/utils/validate';
import AppLink from './Link';
import { getNormalPath } from '@/utils/geshanzsq';
import { ref } from 'vue';
export default {
  __name: 'SidebarItem',
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  setup(__props) {
    const props = __props;
    const onlyOneChild = ref({});
    function hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = [];
      }
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          onlyOneChild.value = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        onlyOneChild.value = {
          ...parent,
          path: '',
          noShowingChildren: true
        };
        return true;
      }
      return false;
    }
    function resolvePath(routePath, routeQuery) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(props.basePath)) {
        return props.basePath;
      }
      if (routeQuery) {
        const query = JSON.parse(routeQuery);
        return {
          path: getNormalPath(props.basePath + '/' + routePath),
          query: query
        };
      }
      return getNormalPath(props.basePath + '/' + routePath);
    }
    function hasTitle(title) {
      if (title.length > 5) {
        return title;
      } else {
        return '';
      }
    }
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_sidebar_item = _resolveComponent("sidebar-item", true);
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      return !__props.item.hidden ? (_openBlock(), _createElementBlock("div", _hoisted_1, [hasOneShowingChild(__props.item.children, __props.item) && (!onlyOneChild.value.children || onlyOneChild.value.noShowingChildren) && !__props.item.alwaysShow ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [onlyOneChild.value.meta ? (_openBlock(), _createBlock(_unref(AppLink), {
        key: 0,
        to: resolvePath(onlyOneChild.value.path, onlyOneChild.value.query)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu_item, {
          index: resolvePath(onlyOneChild.value.path),
          class: _normalizeClass({
            'submenu-title-noDropdown': !__props.isNest
          })
        }, {
          title: _withCtx(() => [_createElementVNode("span", {
            class: "menu-title",
            title: hasTitle(onlyOneChild.value.meta.title)
          }, _toDisplayString(onlyOneChild.value.meta.title), 9, _hoisted_2)]),
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            "icon-name": onlyOneChild.value.meta.icon || __props.item.meta && __props.item.meta.icon
          }, null, 8, ["icon-name"])]),
          _: 1
        }, 8, ["index", "class"])]),
        _: 1
      }, 8, ["to"])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createBlock(_component_el_sub_menu, {
        key: 1,
        ref: "subMenu",
        index: resolvePath(__props.item.path),
        "popper-append-to-body": ""
      }, _createSlots({
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.item.children, child => {
          return _openBlock(), _createBlock(_component_sidebar_item, {
            key: child.path,
            "is-nest": true,
            item: child,
            "base-path": resolvePath(child.path),
            class: "nest-menu"
          }, null, 8, ["item", "base-path"]);
        }), 128))]),
        _: 2
      }, [__props.item.meta ? {
        name: "title",
        fn: _withCtx(() => [_createVNode(_component_svg_icon, {
          "icon-name": __props.item.meta && __props.item.meta.icon
        }, null, 8, ["icon-name"]), _createElementVNode("span", {
          class: "menu-title",
          title: hasTitle(__props.item.meta.title)
        }, _toDisplayString(__props.item.meta.title), 9, _hoisted_3)]),
        key: "0"
      } : undefined]), 1032, ["index"]))])) : _createCommentVNode("", true);
    };
  }
};