import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
import { scrollTo } from '@/utils/scroll-to';
export default {
  __name: 'index',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50];
      }
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  emits: null,
  setup(__props, {
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const currentPage = computed({
      get() {
        return props.page;
      },
      set(val) {
        emit('update:page', val);
      }
    });
    const pageSize = computed({
      get() {
        return props.limit;
      },
      set(val) {
        emit('update:limit', val);
      }
    });
    function handleSizeChange(val) {
      if (currentPage.value * val > props.total) {
        currentPage.value = 1;
      }
      emit('pagination', {
        page: currentPage.value,
        limit: val
      });
      if (props.autoScroll) {
        scrollTo(0, 800);
      }
    }
    function handleCurrentChange(val) {
      emit('pagination', {
        page: val,
        limit: pageSize.value
      });
      if (props.autoScroll) {
        scrollTo(0, 800);
      }
    }
    return (_ctx, _cache) => {
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{
          hidden: __props.hidden
        }, "pagination-container"])
      }, [_createVNode(_component_el_pagination, {
        background: __props.background,
        "current-page": currentPage.value,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => currentPage.value = $event),
        "page-size": pageSize.value,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => pageSize.value = $event),
        layout: __props.layout,
        "page-sizes": __props.pageSizes,
        "pager-count": __props.pagerCount,
        total: __props.total,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["background", "current-page", "page-size", "layout", "page-sizes", "pager-count", "total"])], 2);
    };
  }
};